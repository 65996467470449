import { createSlice } from "@reduxjs/toolkit";

import { IinstructionResponse } from "../../typings/interfaces/responses";
import { getAllInstructionsAction } from "../actions/instructionsAction";

export interface Instructionstate{
    instructions: IinstructionResponse[] | null;
    instructionsLoading: boolean;
    instructionsError: string | null;
}

const initialState: Instructionstate = {
    instructions: null,
    instructionsLoading: false,
    instructionsError: null,
}

const instructionsSlice = createSlice({
    name: "instructions",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllInstructionsAction.pending, (state) => {
                state.instructions = null;
                state.instructionsLoading = true;
                state.instructionsError = null;
            })
            .addCase(getAllInstructionsAction.fulfilled, (state, action) => {
                state.instructionsError = null;
                state.instructionsLoading = false;
                state.instructions = (action.payload as any).body;
            })
            .addCase(getAllInstructionsAction.rejected, (state, action) => {
                state.instructions = null;
                state.instructionsLoading = false;
                state.instructionsError = (action.payload as any).message;
            })         
    }
});

export default instructionsSlice.reducer;
export const instructionSliceActions = instructionsSlice.actions;
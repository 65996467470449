import { FC, useEffect, useRef, useState } from "react";
// import axios from "axios";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Resolver } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  PhoneInput,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaidIcon from "@mui/icons-material/Paid";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

import { CustomButton, CustomTextBox, PosLayout } from "../../../components";
import { CustomCheckBox } from "../../../components/atoms/checkbox/custom-checkbox";
import { CustomSelectBox } from "../../../components/atoms/custom-select-box/custom-select-box";
import { HeaderTitle } from "../../../components/atoms/header-title/header-title";
import { MultiTabSelector } from "../../../components/atoms/multi-tab-selector/multi-tab-selector";
import { Panel } from "../../../components/common/panel/panel";
import { PosInnerPageLayout } from "../../../components/layouts/pos-layout/PosInnerPageLayout";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  createCustomerAction,
  getCustomerByIdAction,
  getUserNotesByIdAction,
  updateCustomerAction,
} from "../../../redux/actions/customerAction";
import StoreCreditsModal from "../../../components/pos/store-credits-modal/store-credits-modal";
import { CustomerSliceAction } from "../../../redux/slice/customerSlice";

import "react-international-phone/style.css";
import { handleTabAndEnter } from "../../../util/common-functions";
import UserNotesModal from "../../../components/pos/notes-modal/userNotesModal";
import { states } from "../../../typings/enum/USA-states";
import { useUserNote } from "../../../providers/UserNoteProvider";
import { NoteOccasion } from "../../../typings/enum/noteOccasions";
import ConfirmDialog from "../../../components/atoms/confirm-box/confirm-box";
import {
  ICreateCustomerRequest,
  IUpdateCustomerRequest,
} from "../../../typings/interfaces/requests/customer-requests";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

// Define the schema for the form data
const schema = Yup.object().shape({
  id: Yup.string().notRequired(),
  createdTime: Yup.string().notRequired(),
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  middleName: Yup.string(),
  mobilePhone: Yup.string()
    .matches(/^\+\d{11,12}$/)
    .required(),
  landPhone: Yup.string().matches(/^\+\d$|^\+\d{11,12}$/),
  email: Yup.string().email(),
  personalAddressLine1: Yup.string().required(),
  personalAddressLine2: Yup.string(),
  personalCity: Yup.string().required(),
  personalState: Yup.string().required(),
  personalZip: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  billingAddressLine1: Yup.string().required(),
  billingAddressLine2: Yup.string(),
  billingCity: Yup.string().required(),
  billingState: Yup.string().required(),
  billingZip: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  priceList: Yup.string(),
  discount: Yup.string(),
  subEmailPromotions: Yup.boolean(),
  subEmailMonthlyStatements: Yup.boolean(),
  subEmailInvoices: Yup.boolean(),
  subMiscellaneous: Yup.boolean(),
  preferenceStarch: Yup.array(Yup.string()),
  preferenceShirt: Yup.array(Yup.string()),
  pickupPref: Yup.array(Yup.string()),
  pantsPref: Yup.array(Yup.string()),
  customerTypePref: Yup.array(Yup.string())
    .min(1, "At least one customer type must be selected")
    .required(),
  customerBagPref: Yup.array(Yup.string()),
  billingSameAsPersonalAddress: Yup.boolean(),
});

type FormData = Yup.InferType<typeof schema>;

// Define the resolver options
const resolver: Resolver<FormData> = yupResolver(schema);

const phoneCountries = defaultCountries.filter((country) => {
  const { iso2 } = parseCountry(country);
  return ["us"].includes(iso2);
});

const PanelLayout: FC<{
  title: string;
  children: React.ReactNode;
  headerRight?: React.ReactNode;
}> = (props) => {
  const { title, children, headerRight } = props;
  return (
    <Panel>
      <Box sx={{ padding: "2rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: "1.25rem",
          }}
        >
          <HeaderTitle variation="panel_header" title={title} />
          {headerRight}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Panel>
  );
};

export interface CreateCustomerPageProps {
  mode?: "edit" | "create";
}

const CreateCustomerPage: FC<CreateCustomerPageProps> = ({
  mode = "create",
}) => {
  const params = useParams();
  const customerId = params.id;
  const dispatch = useAppDispatch();
  const { customer } = useAppSelector((state) => state.customer);
  const { getDiscountByStoreResponse } = useAppSelector(
    (state) => state.discount
  );
  const [phone, setPhone] = useState("");
  const [mobile, setMobile] = useState("");
  const [openNotesModal, setOpenNotesModal] = useState<boolean>(false);
  const [openStoreCreditsModal, setOpenStoreCreditsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [discardDialogOpen, setDiscardDialogOpen] = useState(false);
  const [isDirtyState, setIsDirtyState] = useState(false);

  const { showNote } = useUserNote();
  const { showSnackbar } = useSnackbarContext();

  const handleOnCloseStoreCreditsModal = () => {
    setOpenStoreCreditsModal(false);
  };

  const handleOnNotesModalClose = () => setOpenNotesModal(false);

  // ref array
  // make inputRef array with 42 ref

  useEffect(() => {
    return () => {
      dispatch(CustomerSliceAction.clearCustomer());
    };
  }, []);
  const inputRef = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  useEffect(() => {
    if (mode === "create") {
      const currentValues = getValues();
      currentValues.customerTypePref = ["RETAIL"];
      reset(currentValues, { keepDirty: false });
      // setValue("customerTypePref", ["RETAIL"], { shouldDirty: false });
      setValue("personalState", states.ALABAMA, { shouldDirty: false });
      setValue("billingState", states.ALABAMA, { shouldDirty: false });
    }
  }, []);

  useEffect(() => {
    if (getDiscountByStoreResponse) {
      const currentValues = getValues();
      currentValues.discount = String(getDiscountByStoreResponse.body[0].id);
      reset(currentValues, { keepDirty: false });
      //   setValue("discount", String(getDiscountByStoreResponse.body[0].id), {
      //     shouldDirty: false,
      //   });
    }
  }, [getDiscountByStoreResponse]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    clearErrors,
    setValue,
    getValues,
    watch,
    trigger,
  } = useForm<FormData>({
    defaultValues: customer as any,
    resolver: resolver,
  });

  const changeFormDirtyState = (isDirty: boolean) => {
    const currentValues = getValues();
    reset(currentValues, { keepDirty: isDirty });
  };

  const navigate = useNavigate();
  const handleOnBackClick = () => {
    // check is the form edited or not. then display confirmation dialog if edited
    if (isDirty) {
      setDiscardDialogOpen(true);
    } else {
      redirectPage();
    }
  };
  const handleOnFinish = (userId: string) => {
    navigate(`/pos/${userId}`);
  };
  const redirectPage = () => {
    if (mode === "create") navigate("/pos/lobby");
    else navigate(`/pos/${customerId}`);
  };

  useEffect(() => {
    if (customerId) {
      fetchUser();
      dispatch(getUserNotesByIdAction(customerId as string)).then(
        (res: any) => {
          showNote(NoteOccasion.ONVIEW_EDIT, res.payload);
        }
      );
    }
  }, [customerId]);

  const fetchUser = () => {
    if (customerId) dispatch(getCustomerByIdAction(customerId));
  };

  const watchSameAsPersonalAddress = watch(
    "billingSameAsPersonalAddress",
    false
  );

  const billingState = watch("billingState");
  const personalState = watch("personalState");

  const onSubmit = (data: FormData) => {
    trigger();
    if (!isValid) return;
    setIsLoading(true);
    data.landPhone = data.landPhone?.length === 2 ? "" : data.landPhone;
    data.mobilePhone = data.mobilePhone?.length === 2 ? "" : data.mobilePhone;
    if (mode === "edit") {
      data.id = customerId;
      data.createdTime = customer?.createdTime;
      // axios.put(UPDATE_CUSTOMER, data).then((res) => {
      //   setIsLoading(false);
      //   // handleOnBackClick();
      //   customerId && handleOnFinish(customerId);
      // });
      dispatch(updateCustomerAction(data as IUpdateCustomerRequest)).then(
        (res: any) => {
          setIsLoading(false);
          // res.payload.body.id && handleOnFinish(res.payload.body.id);

          res.payload.body?.id
            ? handleOnFinish(res.payload.body?.id)
            : showSnackbar("Failed to update customer", "error");
        }
      );
    } else {
      // axios.post(CREATE_CUSTOMER, data).then((res) => {
      //   setIsLoading(false);
      //   // handleOnBackClick();
      //   res.data.body?.id
      //     ? handleOnFinish(res.data.body.id)
      //     : null
      // });
      dispatch(createCustomerAction(data as ICreateCustomerRequest)).then(
        (res: any) => {
          setIsLoading(false);
          // res.payload.body.id && handleOnFinish(res.payload.body.id);

          res.payload.body?.id
            ? handleOnFinish(res.payload.body?.id)
            : showSnackbar("Failed to create customer", "error");
        }
      );
    }
  };

  const updateBillingAddress = () => {
    if (getValues("billingSameAsPersonalAddress")) {
      setValue("billingAddressLine1", getValues("personalAddressLine1"));
      setValue("billingAddressLine2", getValues("personalAddressLine2"));
      setValue("billingCity", getValues("personalCity"));
      setValue("billingState", getValues("personalState"));
      setValue("billingZip", getValues("personalZip"));
      clearErrors([
        "billingAddressLine1",
        "billingAddressLine2",
        "billingCity",
        "billingState",
        "billingZip",
      ]);
    } else {
      setValue("billingAddressLine1", "");
      setValue("billingAddressLine2", "");
      setValue("billingCity", "");
      setValue("billingState", states.ALABAMA);
      setValue("billingZip", "");
    }
  };

  useEffect(() => {
    updateBillingAddress();
  }, [watchSameAsPersonalAddress]);

  const handleOnChangeMobile = (value: string) => {
    setMobile(value);
    setValue("mobilePhone", value);
  };

  const handleOnChangeLand = (value: string) => {
    setPhone(value);
    setValue("landPhone", value);
    // console.log(getValues("landPhone"));
  };

  useEffect(() => {
    // set values after customer get values
    if (customer) {
      setValue("firstName", customer.firstName);
      setValue("middleName", customer.middleName ?? "");
      setValue("lastName", customer.lastName);
      setValue("mobilePhone", customer.mobilePhone ?? "");
      setValue("landPhone", customer.landPhone ?? "");
      setValue("email", customer.email);
      // setValue("priceList", customer);
      setValue("discount", customer.discount ?? "");
      setValue("subEmailPromotions", customer.subEmailPromotions ?? false);
      setValue(
        "subEmailMonthlyStatements",
        customer.subEmailMonthlyStatements ?? false
      );
      setValue("subEmailInvoices", customer.subEmailInvoices ?? false);
      setValue("subMiscellaneous", customer.subMiscellaneous ?? false);
      setValue("preferenceStarch", customer.preferenceStarch);
      setValue("preferenceShirt", customer.preferenceShirt);
      setValue("pickupPref", customer.pickupPref);
      setValue("pantsPref", customer.pantsPref);
      setValue("customerTypePref", customer.customerTypePref);
      // setValue("customerBagPref", customer.customerBagPref);
      setValue("personalAddressLine1", customer.personalAddressLine1);
      setValue("personalAddressLine2", customer.personalAddressLine2);
      setValue("personalCity", customer.personalCity);
      setValue("personalState", customer.personalState);
      setValue("personalZip", customer.personalZip);
      setValue("billingAddressLine1", customer.billingAddressLine1);
      setValue("billingAddressLine2", customer.billingAddressLine2);
      setValue("billingCity", customer.billingCity);
      setValue("billingState", customer.billingState);
      setValue("billingZip", customer.billingZip);
    }
    if (!customer) {
      setValue("personalState", states.ALABAMA);
      setValue("billingState", states.ALABAMA);
    }
  }, [customer]);

  useEffect(() => {
    if (customer) {
      setValue("customerTypePref", ["RETAIL"]);
    }
  }, []);

  const renderStateMenuItems = () => {
    return Object.keys(states).map((key) => (
      <MenuItem value={states[key as keyof typeof states]} key={key}>
        {states[key as keyof typeof states]}
      </MenuItem>
    ));
  };

  useEffect(() => {
    if (!isValid) {
      // console log what is invalid
      console.log(errors);
    }
  }, [isValid]);

  return (
    <PosLayout>
      <PosInnerPageLayout
        title={`${mode === "create" ? "New" : "Update"} Customer`}
        onClickBack={handleOnBackClick}
      >
        <ConfirmDialog
          open={discardDialogOpen}
          title="Discard Confirmation"
          message="If you exit, your changes will be discarted"
          onCancel={() => setDiscardDialogOpen(false)}
          onConfirm={() => redirectPage()}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Grid
              item
              xs={8}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              <Grid
                container
                sx={{
                  width: "100%",
                }}
              >
                {/* Personal Information */}
                <Grid
                  item
                  xs={6}
                  sx={{ padding: "0.57rem", minHeight: "27rem" }}
                >
                  <PanelLayout title="Personal Information">
                    {/* Name Inputs */}
                    <Grid container sx={{ mt: "2.71rem" }}>
                      <Grid item xs={2}>
                        <HeaderTitle variation="field_label" title="Name" />
                      </Grid>
                      <Grid container item xs={10}>
                        <Grid item xs={4} sx={{ pr: "0.57rem" }}>
                          <Controller
                            name="firstName"
                            control={control}
                            rules={{
                              required: {
                                message: "First Name is required",
                                value: true,
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                autoFocus={true}
                                tabIndex={1}
                                inputRef={inputRef[0]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 0)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="First"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4} sx={{ pr: "0.57rem" }}>
                          <Controller
                            name="middleName"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={2}
                                inputRef={inputRef[1]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 1)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Middle"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name="lastName"
                            control={control}
                            // rules={{
                            //   required: {
                            //     message: "Last Name is required",
                            //     value: true,
                            //   },
                            // }}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={2}
                                inputRef={inputRef[2]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 2)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Last"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Name Inputs */}

                    <Grid container sx={{ mt: "0.67rem" }}>
                      <Grid item xs={2}>
                        <HeaderTitle variation="field_label" title="Mobile" />
                      </Grid>
                      <Grid item xs={10}>
                        <PhoneInput
                          disableDialCodeAndPrefix={true}
                          defaultCountry="us"
                          value={getValues("mobilePhone") as string}
                          onChange={handleOnChangeMobile}
                          countries={phoneCountries}
                          inputStyle={{
                            width: "100%",
                            height: "2.714rem",
                          }}
                          style={{
                            height: "2.857rem",
                            border: `0.0714285714rem solid ${
                              errors.mobilePhone ? "red" : "silver"
                            }`,
                            backgroundColor: Colors.WHITE,
                            borderRadius: "0.285714286rem",
                          }}
                          inputProps={{
                            tabIndex: 4,
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 3),
                          }}
                          inputRef={inputRef[3]}
                          placeholder="XXX XXX XXXX"
                        />
                        <Controller
                          name="mobilePhone"
                          control={control}
                          render={({ field, fieldState }) => (
                            <PhoneInput
                              inputProps={field}
                              {...field}
                              defaultCountry="us"
                              value={getValues("mobilePhone") as string}
                              inputStyle={{
                                display: "none",
                                height: "2.714rem",
                              }}
                              style={{
                                display: "none",
                                height: "2.857rem",
                              }}
                              disabled
                            />
                          )}
                        />
                        {/* <Controller
                          name="mobilePhone"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Mobile Phone number is required",
                            },
                            pattern: {
                              value: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
                              message:
                                "Please enter a valid mobile phone number",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              value={getValues("mobilePhone") as string}
                              onChange={handleOnChangeMobile}
                              countries={phoneCountries}
                              inputStyle={{
                                width: "100%",
                                height: "2.714rem",
                              }}
                              style={{
                                height: "2.857rem",
                                border: `0.0714285714rem solid ${
                                  fieldState.invalid ? "red" : "silver"
                                }`,
                                backgroundColor: Colors.WHITE,
                                borderRadius: "0.285714286rem",
                              }}
                              placeholder="XXX XXX XXXX"
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mt: "0.67rem" }}>
                      <Grid item xs={2}>
                        <HeaderTitle variation="field_label" title="Home" />
                      </Grid>
                      <Grid item xs={10}>
                        <PhoneInput
                          defaultCountry="us"
                          value={getValues("landPhone") as string}
                          disableDialCodeAndPrefix={true}
                          onChange={handleOnChangeLand}
                          countries={phoneCountries}
                          inputStyle={{
                            width: "100%",
                            height: "2.714rem",
                          }}
                          style={{
                            height: "2.857rem",
                            border: `0.0714285714rem solid ${
                              errors.landPhone ? "red" : "silver"
                            }`,
                            backgroundColor: Colors.WHITE,
                            borderRadius: "0.285714286rem",
                          }}
                          inputProps={{
                            tabIndex: 5,
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 4),
                          }}
                          inputRef={inputRef[4]}
                          placeholder="XXX XXX XXXX"
                        />
                        <Controller
                          name="landPhone"
                          control={control}
                          render={({ field, fieldState }) => (
                            // <CustomTextBox
                            //   {...field}
                            //   error={fieldState.invalid}
                            //   type="text"
                            //   sx={{
                            //     width: "100%",
                            //     backgroundColor: Colors.WHITE,
                            //   }}
                            //   placeholder="Phone Number"
                            // />
                            <PhoneInput
                              inputProps={field}
                              {...field}
                              defaultCountry="us"
                              value={getValues("landPhone") as string}
                              inputStyle={{
                                display: "none",
                                height: "2.714rem",
                              }}
                              style={{
                                display: "none",
                                height: "2.857rem",
                              }}
                              disabled
                            />
                          )}
                        />
                        {/* <Controller
                          name="landPhone"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Phone number is required",
                            },
                            pattern: {
                              value: /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/,
                              message: "Please enter a valid phone number",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <PhoneInput
                              {...field}
                              defaultCountry="us"
                              value={getValues("landPhone") as string}
                              onChange={handleOnChangeLand}
                              countries={phoneCountries}
                              inputStyle={{
                                width: "100%",
                                height: "2.714rem",
                              }}
                              style={{
                                height: "2.857rem",
                                border: `0.0714285714rem solid ${
                                  fieldState.invalid ? "red" : "silver"
                                }`,
                                backgroundColor: Colors.WHITE,
                                borderRadius: "0.285714286rem",
                              }}
                              placeholder="XXX XXX XXXX"
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: "0.67rem" }}>
                      <Grid item xs={2}>
                        <HeaderTitle variation="field_label" title="Email" />
                      </Grid>
                      <Grid item xs={10}>
                        <Controller
                          name="email"
                          control={control}
                          // rules={{
                          //   required: {
                          //     message: "Email is required",
                          //     value: true,
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <CustomTextBox
                              {...field}
                              error={fieldState.invalid}
                              type="text"
                              tabIndex={6}
                              inputRef={inputRef[5]}
                              onKeyDown={(event) =>
                                handleTabAndEnter(inputRef, event, 5)
                              }
                              sx={{
                                width: "100%",
                                backgroundColor: Colors.WHITE,
                                "& .MuiInputBase-input": {
                                  textAlign: "center",
                                },
                              }}
                              placeholder="johndoe@example.com"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </PanelLayout>
                </Grid>
                {/* Personal Information */}

                {/* Miscellaneous */}
                <Grid
                  item
                  xs={6}
                  sx={{ padding: "0.57rem", minHeight: "27rem" }}
                >
                  <PanelLayout title="Miscellaneous">
                    <Grid container sx={{ mt: "2.57rem" }}>
                      <Grid item xs={3}>
                        <HeaderTitle
                          variation="field_label"
                          title=" Price List"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Controller
                          name="priceList"
                          control={control}
                          // rules={{
                          //   required: {
                          //     message: "Price List is required",
                          //     value: true,
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <CustomSelectBox
                              {...field}
                              error={Boolean(fieldState.error)}
                              formControlProps={{
                                fullWidth: true,
                                sx: {
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiSelect-select": {
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  },
                                  "& .MuiInputBase-root": {
                                    justifyContent: "center",
                                  },
                                },
                              }}
                              defaultValue="1"
                              tabIndex={12}
                              ref={inputRef[11]}
                              inputProps={{
                                tabIndex: 12,
                                onBlur: (event) => {
                                  handleTabAndEnter(inputRef, event, 11);
                                },
                              }}
                              // onKeyDown={(event) =>
                              //   handleTabAndEnter(inputRef, event, 12)
                              // }
                            >
                              <MenuItem value="1">List 1</MenuItem>
                              <MenuItem value="2">List 2</MenuItem>
                              <MenuItem value="3">List 3</MenuItem>
                            </CustomSelectBox>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: "0.67rem" }}>
                      <Grid item xs={3}>
                        <HeaderTitle
                          variation="field_label"
                          title=" Discounts"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Controller
                          name="discount"
                          control={control}
                          // rules={{
                          //   required: {
                          //     message: "Discount is required",
                          //     value: true,
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <CustomSelectBox
                              {...field}
                              error={Boolean(fieldState.error)}
                              formControlProps={{
                                fullWidth: true,
                                sx: {
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiSelect-select": {
                                    display: "flex",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  },
                                  "& .MuiInputBase-root": {
                                    justifyContent: "center",
                                  },
                                },
                              }}
                              tabIndex={13}
                              ref={inputRef[12]}
                              inputProps={{
                                tabIndex: 13,
                                onBlur: (event) => {
                                  handleTabAndEnter(inputRef, event, 12);
                                },
                              }}
                              value={getValues("discount")}
                              onChange={(e) => {
                                setValue("discount", e.target.value as string);
                              }}
                              // onKeyDown={(event) =>
                              //   handleTabAndEnter(inputRef, event, 13)
                              // }
                            >
                              {getDiscountByStoreResponse?.body.map(
                                (discount) => (
                                  <MenuItem value={discount.id}>
                                    {discount.discountName}
                                  </MenuItem>
                                )
                              )}
                            </CustomSelectBox>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: "2.57rem" }}>
                      <Grid item xs={3}>
                        <HeaderTitle
                          variation="field_label"
                          title="Email Pref."
                        />
                      </Grid>

                      <Grid container item xs={9}>
                        <Grid item xs={6} sx={{ ml: "1rem" }}>
                          <Controller
                            name="subEmailPromotions"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomCheckBox
                                {...field}
                                label="Promotions"
                                // tabIndex={15}
                                // ref={inputRef[14]}
                                // onKeyDown={(event) =>
                                //   handleTabAndEnter(inputRef, event, 14)
                                // }
                                // inputProps={{
                                //   tabIndex: 15,
                                //   onKeyDown: (event) => {
                                //     handleTabAndEnter(inputRef, event, 14);
                                //   },
                                // }}
                                tabIndex={14}
                                ref={inputRef[13]}
                                inputProps={{
                                  tabIndex: 14,
                                  onKeyDown: (event) => {
                                    handleTabAndEnter(inputRef, event, 13);
                                  },
                                }}
                                checked={getValues("subEmailPromotions")}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sx={{ ml: "-1rem" }}>
                          <Controller
                            name="subEmailMonthlyStatements"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomCheckBox
                                {...field}
                                label="Monthly Statements"
                                // tabIndex={16}
                                tabIndex={15}
                                ref={inputRef[14]}
                                inputProps={{
                                  tabIndex: 15,
                                  onKeyDown: (event) => {
                                    handleTabAndEnter(inputRef, event, 14);
                                  },
                                }}
                                checked={getValues("subEmailMonthlyStatements")}
                              />
                            )}
                          />
                        </Grid>

                        <Grid container sx={{ mt: "0.57rem" }}>
                          <Grid item xs={6} sx={{ ml: "1rem" }}>
                            <Controller
                              name="subEmailInvoices"
                              control={control}
                              // rules={{}}
                              render={({ field, fieldState }) => (
                                <CustomCheckBox
                                  {...field}
                                  label="Invoices"
                                  // tabIndex={17}
                                  tabIndex={16}
                                  ref={inputRef[15]}
                                  inputProps={{
                                    tabIndex: 16,
                                    onKeyDown: (event) => {
                                      handleTabAndEnter(inputRef, event, 15);
                                    },
                                  }}
                                  checked={getValues("subEmailInvoices")}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sx={{ ml: "-1rem" }}>
                            <Controller
                              name="subMiscellaneous"
                              control={control}
                              // rules={{}}
                              render={({ field, fieldState }) => (
                                <CustomCheckBox
                                  {...field}
                                  label="Order Status Updates"
                                  // tabIndex={18}
                                  tabIndex={17}
                                  ref={inputRef[16]}
                                  inputProps={{
                                    tabIndex: 17,
                                    onKeyDown: (event) => {
                                      handleTabAndEnter(inputRef, event, 16);
                                    },
                                  }}
                                  checked={getValues("subMiscellaneous")}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </PanelLayout>
                </Grid>
                {/* Miscellaneous */}

                {/* Address */}
                <>
                  {/* Personal Address */}
                  <Grid
                    item
                    xs={6}
                    sx={{ padding: "0.57rem", minHeight: "29.6rem" }}
                  >
                    <PanelLayout title="Personal Address">
                      {/* Personal Street */}
                      <Grid container sx={{ mt: "2.1rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="Street" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="personalAddressLine1"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={7}
                                inputRef={inputRef[6]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 6)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Street"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Personal Apt/Unit */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle
                            variation="field_label"
                            title="Apt/Unit"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="personalAddressLine2"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={8}
                                inputRef={inputRef[7]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 7)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Apt/Unit"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Personal City */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="City" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="personalCity"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={9}
                                inputRef={inputRef[8]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 8)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="City"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Personal State */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="State" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="personalState"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomSelectBox
                                {...field}
                                error={Boolean(fieldState.error)}
                                formControlProps={{
                                  fullWidth: true,
                                  sx: {
                                    width: "100%",
                                    backgroundColor: Colors.WHITE,
                                    "& .MuiSelect-select": {
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  },
                                }}
                                ref={inputRef[9]}
                                inputProps={{
                                  tabIndex: 10,
                                  onBlur: (event) =>
                                    handleTabAndEnter(inputRef, event, 9),
                                }}
                                onChange={(e) => {
                                  setValue(
                                    "personalState",
                                    (e.target.value || states.ALABAMA) as string
                                  );
                                }}
                                value={
                                  getValues("personalState") || states.ALABAMA
                                }
                              >
                                {renderStateMenuItems()}
                              </CustomSelectBox>
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Personal Zip */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="Zip" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="personalZip"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                error={fieldState.invalid}
                                type="number"
                                tabIndex={11}
                                inputRef={inputRef[10]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 10)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                  "& input::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                    {
                                      "-webkit-appearance": "none",
                                      margin: 0,
                                    },
                                  "& inputMozAppearance": "textfield",
                                }}
                                placeholder="Zip"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </PanelLayout>
                  </Grid>
                  {/* Personal Address */}

                  {/* Billing Address */}
                  <Grid
                    item
                    xs={6}
                    sx={{ padding: "0.57rem", minHeight: "27rem" }}
                  >
                    <PanelLayout
                      title="Billing Address"
                      headerRight={
                        <Controller
                          name="billingSameAsPersonalAddress"
                          control={control}
                          // rules={{}}
                          render={({ field, fieldState }) => (
                            <CustomCheckBox
                              {...field}
                              label="Same as personal address"
                              // tabIndex={19}

                              // onKeyDown={(event) => {
                              //   handleTabAndEnter(inputRef, event, 18);
                              // }}
                              tabIndex={18}
                              ref={inputRef[17]}
                              inputProps={{
                                tabIndex: 18,
                                onKeyDown: (event) => {
                                  handleTabAndEnter(inputRef, event, 17);
                                },
                              }}
                              checked={getValues(
                                "billingSameAsPersonalAddress"
                              )}
                            />
                          )}
                        />
                      }
                    >
                      {/* billing Street */}
                      <Grid container sx={{ mt: "2.1rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="Street" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="billingAddressLine1"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                disabled={watchSameAsPersonalAddress}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={19}
                                inputRef={inputRef[18]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 18)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Street"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* billing Apt/Unit */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle
                            variation="field_label"
                            title="Apt/Unit"
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="billingAddressLine2"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                disabled={watchSameAsPersonalAddress}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={20}
                                inputRef={inputRef[19]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 19)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="Apt/Unit"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* billing City */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="City" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="billingCity"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                disabled={watchSameAsPersonalAddress}
                                error={fieldState.invalid}
                                type="text"
                                tabIndex={21}
                                inputRef={inputRef[20]}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 20)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                }}
                                placeholder="City"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* billing State */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title="State" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="billingState"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomSelectBox
                                {...field}
                                error={Boolean(fieldState.error)}
                                formControlProps={{
                                  fullWidth: true,
                                  sx: {
                                    width: "100%",
                                    backgroundColor: Colors.WHITE,
                                    "& .MuiSelect-select": {
                                      textAlign: "center",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    },
                                  },
                                }}
                                ref={inputRef[21]}
                                inputProps={{
                                  tabIndex: 22,
                                  onBlur: (event) =>
                                    handleTabAndEnter(inputRef, event, 21),
                                  disabled: watchSameAsPersonalAddress,
                                }}
                                onChange={(e) => {
                                  setValue(
                                    "billingState",
                                    (e.target.value || states.ALABAMA) as string
                                  );
                                }}
                                disabled={watchSameAsPersonalAddress}
                                value={
                                  getValues("billingState") || states.ALABAMA
                                }
                              >
                                {renderStateMenuItems()}
                              </CustomSelectBox>
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* billing Zip Code */}
                      <Grid container sx={{ mt: "0.67rem" }}>
                        <Grid item xs={2}>
                          <HeaderTitle variation="field_label" title=" Zip" />
                        </Grid>
                        <Grid item xs={10}>
                          <Controller
                            name="billingZip"
                            control={control}
                            // rules={{}}
                            render={({ field, fieldState }) => (
                              <CustomTextBox
                                {...field}
                                disabled={watchSameAsPersonalAddress}
                                inputProps={{
                                  disabled: watchSameAsPersonalAddress,
                                }}
                                error={fieldState.invalid}
                                type="number"
                                inputRef={inputRef[22]}
                                tabIndex={23}
                                onKeyDown={(event) =>
                                  handleTabAndEnter(inputRef, event, 22)
                                }
                                sx={{
                                  width: "100%",
                                  backgroundColor: Colors.WHITE,
                                  "& .MuiInputBase-input": {
                                    textAlign: "center",
                                  },
                                  "& input": {
                                    py: "0rem",
                                    height: "2.85642857rem",
                                  },
                                  "& input::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                                    {
                                      "-webkit-appearance": "none",
                                      margin: 0,
                                    },
                                  "& inputMozAppearance": "textfield",
                                }}
                                placeholder="Zip"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </PanelLayout>
                  </Grid>

                  {/* Billing Address */}
                </>
                {/* Address */}
              </Grid>
              <Grid
                container
                sx={{
                  height: "14.13rem",
                }}
              ></Grid>
              {/* Card Information */}
              <Grid
                container
                spacing={2}
                sx={{ padding: "0.57rem", marginTop: "-15.2rem" }}
              >
                <Grid item xs={3}>
                  <PanelLayout title="">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "2.2rem",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          color: Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        Card Information
                      </span>
                    </Box>
                  </PanelLayout>
                </Grid>

                {/* Update Cards */}
                <Grid item xs={1.8}>
                  <Panel>
                    <CustomButton
                      sx={{
                        background: "none",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "10rem",
                        cursor: "pointer",
                      }}
                    >
                      <BrowserUpdatedIcon
                        sx={{ fontSize: "3rem", color: Colors.SECONDARY }}
                      />
                      <span
                        style={{
                          marginTop: "1rem",
                          color: Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Update Cards
                      </span>
                    </CustomButton>
                  </Panel>
                </Grid>

                {/* Tax Excemption */}
                <Grid item xs={1.8}>
                  <Panel>
                    <CustomButton
                      sx={{
                        background: "none",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "10rem",
                        cursor: "pointer",
                      }}
                    >
                      <ReceiptIcon
                        sx={{ fontSize: "3rem", color: Colors.SECONDARY }}
                      />
                      <span
                        style={{
                          marginTop: "1rem",
                          color: Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Tax Exemption
                      </span>
                    </CustomButton>
                  </Panel>
                </Grid>

                {/* Store Credits */}
                <Grid item xs={1.8}>
                  <Panel>
                    <CustomButton
                      sx={{
                        background: "none",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "10rem",
                        cursor: "pointer",
                      }}
                      disabled={mode === "create"}
                      onClick={() => setOpenStoreCreditsModal(true)}
                    >
                      <PaidIcon
                        sx={{
                          fontSize: "3rem",
                          color:
                            mode === "create"
                              ? Colors.ACCENT_GRAY
                              : Colors.SECONDARY,
                        }}
                      />
                      <Typography
                        sx={{
                          marginTop: "1rem",
                          color:
                            mode === "create"
                              ? Colors.ACCENT_GRAY
                              : Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Store Credits
                      </Typography>
                    </CustomButton>
                  </Panel>
                  <StoreCreditsModal
                    open={openStoreCreditsModal}
                    onClose={handleOnCloseStoreCreditsModal}
                    isCustomerEdited={isDirtyState}
                    resetDirtyState={changeFormDirtyState}
                  />
                </Grid>

                {/* Manage Notes */}
                <Grid item xs={1.8}>
                  <Panel>
                    <CustomButton
                      sx={{
                        background: "none",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "10rem",
                        cursor: "pointer",
                      }}
                      disabled={mode === "create"}
                      onClick={() => setOpenNotesModal(true)}
                    >
                      <TextSnippetIcon
                        sx={{
                          fontSize: "3rem",
                          color:
                            mode === "create"
                              ? Colors.ACCENT_GRAY
                              : Colors.SECONDARY,
                        }}
                      />
                      <Typography
                        sx={{
                          marginTop: "1rem",
                          color:
                            mode === "create"
                              ? Colors.ACCENT_GRAY
                              : Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Manage Notes
                      </Typography>
                    </CustomButton>
                  </Panel>
                  <UserNotesModal
                    open={openNotesModal}
                    onClose={handleOnNotesModalClose}
                  />
                </Grid>
                {/* Print Barcode */}
                <Grid item xs={1.8}>
                  <Panel>
                    <CustomButton
                      sx={{
                        background: "none",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "10rem",
                        cursor: "pointer",
                      }}
                    >
                      <QrCodeIcon
                        sx={{ fontSize: "3rem", color: Colors.SECONDARY }}
                      />
                      <span
                        style={{
                          marginTop: "1rem",
                          color: Colors.SECONDARY,
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                      >
                        Print Barcode
                      </span>
                    </CustomButton>
                  </Panel>
                </Grid>
              </Grid>
              {/* Notes */}
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", flexDirection: "column" }}>
              <Grid sx={{ padding: "0.57rem", minHeight: "49.6rem" }}>
                {/* Preferences */}
                <PanelLayout title="Preferences">
                  <Grid container sx={{ mt: "0.57rem" }}>
                    <Grid item xs={2}>
                      <HeaderTitle variation="field_label" title="Starch" />
                    </Grid>
                    <Grid item xs={10}>
                      <MultiTabSelector
                        items={[
                          {
                            value: "NONE",
                            label: "None",
                            tabIndex: 24,
                            ref: inputRef[23],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 23),
                          },
                          {
                            value: "LIGHT",
                            label: "Light",
                            tabIndex: 25,
                            ref: inputRef[24],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 24),
                          },
                          {
                            value: "MEDIUM",
                            label: "Medium",
                            tabIndex: 26,
                            ref: inputRef[25],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 25),
                          },
                          {
                            value: "HEAVY",
                            label: "Heavy",
                            tabIndex: 27,
                            ref: inputRef[26],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 26),
                          },
                        ]}
                        value={getValues("preferenceStarch") as Array<string>}
                        onChange={(value) => {
                          setValue("preferenceStarch", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        layout="horizontal"
                        mode="single"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: "0.57rem" }}>
                    <Grid item xs={2}>
                      <HeaderTitle variation="field_label" title="Shirt" />
                    </Grid>
                    <Grid item xs={10}>
                      <MultiTabSelector
                        items={[
                          {
                            value: "HANGER",
                            label: "Hanger",
                            tabIndex: 28,
                            ref: inputRef[27],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 27),
                          },
                          {
                            value: "BOXED",
                            label: "Boxed",
                            tabIndex: 29,
                            ref: inputRef[28],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 28),
                          },
                        ]}
                        value={getValues("preferenceShirt") as Array<string>}
                        onChange={(value) => {
                          setValue("preferenceShirt", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        layout="horizontal"
                        mode="single"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: "0.57rem" }}>
                    <Grid item xs={2}>
                      <HeaderTitle variation="field_label" title="Pickup" />
                    </Grid>
                    <Grid item xs={10}>
                      <MultiTabSelector
                        items={[
                          {
                            value: "REGULAR",
                            label: "Regular",
                            tabIndex: 30,
                            ref: inputRef[29],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 29),
                          },
                          {
                            value: "SPECIAL",
                            label: "Special",
                            tabIndex: 31,
                            ref: inputRef[30],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 30),
                          },
                        ]}
                        value={getValues("pickupPref") as Array<string>}
                        onChange={(value) => {
                          setValue("pickupPref", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        layout="horizontal"
                        mode="single"
                      />
                    </Grid>
                  </Grid>
                  <Grid container sx={{ mt: "0.57rem" }}>
                    <Grid item xs={2}>
                      <HeaderTitle variation="field_label" title="Pants" />
                    </Grid>
                    <Grid item xs={10}>
                      <MultiTabSelector
                        items={[
                          {
                            value: "CREASE",
                            label: "Crease",
                            tabIndex: 32,
                            ref: inputRef[31],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 31),
                          },
                          {
                            value: "NO_CREASE",
                            label: "No Crease",
                            tabIndex: 33,
                            ref: inputRef[32],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 32),
                          },
                        ]}
                        value={getValues("pantsPref") as Array<string>}
                        onChange={(value) => {
                          setValue("pantsPref", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        layout="horizontal"
                        mode="single"
                      />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      background: Colors.ACCENT_GRAY,
                      width: "90%",
                      height: "0.142rem",
                      margin: "2rem auto",
                    }}
                  ></Box>

                  {/* Customer Type */}
                  <Grid container sx={{ mt: "0.57rem", width: "100%" }}>
                    <Grid item xs={3}>
                      <HeaderTitle
                        variation="field_label"
                        title="Customer Type"
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <MultiTabSelector
                        items={[
                          {
                            value: "RETAIL",
                            label: "Retail",
                            tabIndex: 34,
                            ref: inputRef[33],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 33),
                          },
                          {
                            value: "CHARGE_AT_EACH_PICKUP",
                            label: "Retail - Charge at each Pickup",
                            tabIndex: 35,
                            ref: inputRef[34],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 34),
                          },
                          {
                            value: "MONTHLY_CHARGED",
                            label: "Retail - Monthly Charged",
                            tabIndex: 36,
                            ref: inputRef[35],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 35),
                          },
                          {
                            value: "DELIVERY",
                            label: "Retail - Delivery",
                            tabIndex: 37,
                            ref: inputRef[36],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 36),
                          },
                          {
                            value: "COOPERATE",
                            label: "Coorperate",
                            tabIndex: 38,
                            ref: inputRef[37],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 37),
                          },
                          {
                            value: "EMPLOYEE",
                            label: "Employee",
                            tabIndex: 39,
                            ref: inputRef[38],
                            onKeyDown: (event) =>
                              handleTabAndEnter(inputRef, event, 38),
                          },
                        ]}
                        value={getValues("customerTypePref") as Array<string>}
                        onChange={(value) => {
                          setValue("customerTypePref", value, {
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                        }}
                        error={(errors.customerTypePref?.length ?? 0) > 0}
                        layout="vertical"
                        mode="single"
                      />
                    </Grid>
                  </Grid>
                  {/* Customer Type */}
                </PanelLayout>
                {/* Preferences */}
              </Grid>
              <Grid
                sx={{
                  border: "none",
                  background: Colors.BACKGROUND_GRAY,
                  borderRadius: "1.42rem",
                  mt: "0.71rem",
                  marginLeft: "0.5rem",
                  p: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10.13rem",
                  padding: "0.57rem",
                }}
              >
                <CustomButton
                  type="submit"
                  sx={{
                    width: "28.3571428571rem",
                    height: "5.85714285714rem",
                    borderRadius: "0.71rem",
                    padding: "1.64285714rem",
                    fontSize: "2.28rem",
                    alignSelf: "center",
                    marginLeft: "1rem",
                    marginTop: "-1rem",
                  }}
                  tabIndex={40}
                  ref={inputRef[39]}
                  // onKeyDown={(event) =>
                  //   handleTabAndEnter(inputRef, event, 42)
                  // }
                  disabled={!isDirty || isLoading || !isValid}
                >
                  {isLoading ? (
                    <CircularProgress />
                  ) : mode === "edit" ? (
                    "Update Customer"
                  ) : (
                    "Add New Customer"
                  )}
                </CustomButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </PosInnerPageLayout>
    </PosLayout>
  );
};

export default CreateCustomerPage;

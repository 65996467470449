import { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import "swiper/css";

import { Colors } from "../../../../configs";
import { useAppDispatch, useAppSelector } from "../../../../hooks";
import { sortArray } from "../../../../util/array-functions";
import { Swiper, SwiperSlide } from "swiper/react";
import { CustomButton } from "../../../atoms";
import { cloneDeep } from "lodash";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { IInstruction } from "../../../../typings/interfaces/pos/cart-item";
import InstructionPromptModal from "../instruction-prompt-modal/instruction-prompt-modal";
import { getAllInstructionsAction } from "../../../../redux/actions/instructionsAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface IInstructionSectionProps {}

const InstructionSection: FC<IInstructionSectionProps> = () => {
  const dispatch = useAppDispatch();
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const snackbar = useSnackbarContext();

  const { instructions, instructionsError, instructionsLoading } =
    useAppSelector((state) => state.instruction);

  const handleOnClosePrompt = () => {
    setOpenPrompt(false);
  };

  useEffect(() => {
    dispatch(getAllInstructionsAction());
  }, []);

  useEffect(() => {
    if (instructionsError) {
      snackbar.showSnackbar(instructionsError, "error");
    }
  }, [instructionsError]);

  const allInstructions = [
    ...(instructions || []),
    {
      id: 100000,
      instruction: "Custom",
      merchantId: 1,
      storetId: 1,
      deleted: false,
    },
  ];

  const instructionsSorted = sortArray({
    array: allInstructions,
    sortByKey: "instruction",
    fixedFirstItem: "Custom",
  });

  const handleUpdateCartInstructions = (instructions: IInstruction[]) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.instructions = instructions;
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  const isAddedInstruction = (instruction: IInstruction) => {
    return selectedCartItem?.instructions?.find(
      (item) => item.id === instruction.id
    )?.id
      ? true
      : false;
  };

  const onSelectItem = (instruction: IInstruction) => {
    if (instruction.id === 100000) {
      setOpenPrompt(true);
    } else {
      if (isAddedInstruction(instruction)) {
        handleUpdateCartInstructions(
          selectedCartItem?.instructions.filter(
            (item) => instruction.id !== item.id
          ) ?? []
        );
      } else {
        handleUpdateCartInstructions([
          ...(selectedCartItem?.instructions || []),
          instruction,
        ]);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        // background: "red",
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      <InstructionPromptModal
        promptOpen={openPrompt}
        onClose={handleOnClosePrompt}
        onSubmit={handleUpdateCartInstructions}
      />
      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Instructions
      </Typography>

      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(instructionsSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  // height: "10.6428571rem",
                  // background: "red",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {instructionsLoading ? (
                  <CircularProgress sx={{ mx: "auto" }} />
                ) : (
                  instructionsSorted
                    ?.slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                    .map((instruction, i) => (
                      <CustomButton
                        sx={{
                          width: "17.0064286rem",
                          background: isAddedInstruction(
                            instruction as IInstruction
                          )
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                          borderRadius: "0.71rem",
                          textTransform: "capitalize",
                          color: Colors.WHITE,
                          fontSize: "1.14rem",
                          lineHeight: "1.33928571rem",
                          padding: "1.07142857rem 0.857142857rem",
                          "&&:hover": {
                            background: Colors.TEXT_GRAY_DARK,
                          },
                          height: "4.28571429rem",
                        }}
                        key={i}
                        onClick={() =>
                          onSelectItem({
                            id: (instruction as any).id,
                            label: (instruction as any).instruction,
                          })
                        }
                      >
                        {(instruction as any).instruction}
                      </CustomButton>
                    ))
                )}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default InstructionSection;

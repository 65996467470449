import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import { IdamageResponse } from "../../typings/interfaces/responses";
import { GET_ALL_DAMAGES } from "../../configs/apiConfig";

export const getAllDamagesAction = createAsyncThunk(
    "damages/all-damages",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponse<IdamageResponse>>(
                GET_ALL_DAMAGES
            );
            return response.data;
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
)
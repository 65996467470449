import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { BaseResponse } from "../../typings/interfaces";
import { IinstructionResponse } from "../../typings/interfaces/responses";
import { GET_ALL_INSTRUCTIONS } from "../../configs/apiConfig";

export const getAllInstructionsAction = createAsyncThunk(
    "instructions/all-instructions",
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get<BaseResponse<IinstructionResponse>>(
                GET_ALL_INSTRUCTIONS
            );
            return response.data;
        }
        catch (err) {
            if (!err) {
                throw err;
            }
            return rejectWithValue(err);
        }
    }
)

import { FC, useEffect, useState } from "react";
import {
  NextPlan,
  ArrowCircleRight,
  Cancel,
  ReplayCircleFilled,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import CheckInSummary from "./checkinSummary";
import Payment from "./payment";
import Success from "./success";
import Failure from "./failure";
import { render } from "react-thermal-printer";
import dayjs, { Dayjs } from "dayjs";

import CustomModal from "../../common/custom-modal/custom-modal";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { Colors } from "../../../configs";
import { PaymentModalContents } from "../../../typings/enum/paymentModalContents";
import {
  newBillAction,
  newPaymentAction,
} from "../../../redux/actions/cartAction";
import { getTenantId } from "../../../util/common-functions";
import useCart from "../../../hooks/useCart";
import useThermalPrinter from "../../../hooks/thermalPrinter";
import { useTranslation } from "../../../util/translation-provider";
import { getStoreBillTemplate } from "../../../configs/printCopyTemplates";
import getCheckOutDate from "../../../configs/checkoutDateRules";
import { PaymentTypeEnum } from "../../../typings/enum/paymentTypes";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { PaymentRequest } from "../../../typings/interfaces/requests";
import { PaymentCreateResponse } from "../../../typings/interfaces/responses/paymentResponse";
import { BaseResponseSingle } from "../../../typings";
import { IHalfPayment } from "../../../typings/interfaces/payment/halfPayment";
import { IStoreConfigResponse } from "../../../typings/interfaces/responses/store-config-response";
import { ActionTileModal } from "../../atoms/action-tile-modal/action-tile-modal";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";

export interface IPaymentModalData {
  paymentMode: string;
  paidAmount: number;
}

export interface IPaymentModalProps {
  discount: number;
  total: number;
  subTotal?: number;
  open: boolean;
  onClose(): void;
  // onCheckout(data: IPaymentModalData): void;
  content: PaymentModalContents;
}

export interface ModalActionItem {
  icon: JSX.Element;
  label: string;
  onClick: () => void;
  color: string;
}

const PaymentModal: FC<IPaymentModalProps> = ({
  open,
  onClose,
  discount,
  total,
}) => {
  const [openActionTileModal, setOpenActionTileModal] = useState(false);
  const [isCheckInSuccessful] = useState<boolean>(true);
  const [modalActionItems, setModalActionItems] = useState<ModalActionItem[]>(
    []
  );
  const [intiaLRender, setIntiaLRender] = useState(true);
  const navigate = useNavigate();
  const billError = useAppSelector((state) => state.cart.billError);
  const dispatch = useAppDispatch();
  const getStoreConfigResponse = useAppSelector(
    (state) => state.store.getStoreConfigResponse
  );
  const tenantId = getTenantId();
  const { cartTotal, totalDiscountAmount } = useCart();
  const { initialize, print } = useThermalPrinter();
  const translation = useTranslation();
  const { customer } = useAppSelector((state) => state.customer);
  const { cart, paymentHalfDetails } = useAppSelector((state) => state.cart);
  const billTotal = cartTotal - totalDiscountAmount;
  const paidAmount = paymentHalfDetails.halfPaymentAmount;
  const snackbar = useSnackbarContext();
  const params = useParams();

  const [masterSwitch, setMasterSwitch] = useState(false);
  const [sessionSwitches, setSessionSwitches] = useState<
    { id: string; checked: boolean }[]
  >([]);

  const customerId = params.id;

  const successActionItems = [
    {
      icon: <NextPlan />,
      label: "Go to Lobby",
      onClick: () => navigate("/pos/lobby"),
      color: Colors.PRIMARY,
    },
    {
      icon: <ArrowCircleRight />,
      label: "Stay Here",
      onClick: () => setOpenActionTileModal(false),
      color: Colors.PRIMARY,
    },
    {
      icon: <NextPlan />,
      label: "Go to Pickup",
      onClick: () => navigate(`/pos/customer/${customerId}/history`), // To do: this have to change to pickup url after fixing the pickup url
      color: Colors.PRIMARY,
    },
  ];

  const failureActionItems = [
    {
      icon: <Cancel />,
      label: "Cancel",
      onClick: () => setOpenActionTileModal(false),
      color: Colors.BROWN,
    },
    {
      icon: <ReplayCircleFilled />,
      label: "Retry",
      onClick: () => console.log("Retry clicked"),
      color: Colors.BROWN,
    },
  ];

  /*
  isSpecialDay,
  checkInCutOffTime,
  pickupTime,
  workingDays,
  generalServiceDays,
  specialServiceDays,
  */

  const parseTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    const date = new Date();
    date.setHours(hours, minutes, seconds, 0);
    return date;
  };

  const defaultCutOffTime = "10:00:00";
  const defaultPickupTime = "15:00:00";
  const defaultWorkingDays = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ];
  const defaultRegularServiceDays = 2;
  const defaultSpecialServiceDays = 0;

  const checkInCutOffTimeDate = parseTime(
    getStoreConfigResponse?.body?.cutOffTime || defaultCutOffTime
  );
  const pickupTimeDate = parseTime(
    getStoreConfigResponse?.body?.pickupTime || defaultPickupTime
  );

  const [masterDate, setMasterDate] = useState<Dayjs | null>(null);
  const [masterTime, setMasterTime] = useState<Dayjs | null>(null);
  const [sessionDates, setSessionDates] = useState<
    { id: string; specialDate: Dayjs }[]
  >([]);
  const [sessionTimes, setSessionTimes] = useState<
    { id: string; specialTime: Dayjs }[]
  >([]);
  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.CASH
  );

  useEffect(() => {
    setMasterDate(
      getCheckOutDate({
        isSpecialDay: masterSwitch,
        checkInCutOffTime: checkInCutOffTimeDate,
        pickupTime: pickupTimeDate,
        workingDays:
          getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
        generalServiceDays:
          getStoreConfigResponse?.body?.daysToDeliver ??
          defaultRegularServiceDays,
        specialServiceDays:
          getStoreConfigResponse?.body?.specialDaysToDeliver ??
          defaultSpecialServiceDays,
      })
    );
    setMasterTime(
      dayjs(
        new Date().setHours(
          getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
          getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
          0,
          0
        )
      )
    );
  }, [getStoreConfigResponse]);

  useEffect(() => {
    setSessionSwitches(
      cart.map((item) => ({ id: item.sessionId, checked: false }))
    );
    setSessionDates(
      cart.map((item) => ({
        id: item.sessionId,
        specialDate: getCheckOutDate({
          isSpecialDay: false,
          checkInCutOffTime: checkInCutOffTimeDate,
          pickupTime: pickupTimeDate,
          workingDays:
            getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
          generalServiceDays:
            getStoreConfigResponse?.body?.daysToDeliver ??
            defaultRegularServiceDays,
          specialServiceDays:
            getStoreConfigResponse?.body?.specialDaysToDeliver ??
            defaultSpecialServiceDays,
        }),
      }))
    );
    setSessionTimes(
      cart.map((item) => ({
        id: item.sessionId,
        specialTime: dayjs(
          new Date().setHours(
            getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
            getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
            0,
            0
          )
        ),
      }))
    );
  }, [cart]);

  useEffect(() => {
    setSessionDates((prevSessionDates) => {
      return prevSessionDates.map((sessionDate) => {
        const sessionSwitch = sessionSwitches.find(
          (item) => item?.id === sessionDate?.id
        );
        if (sessionSwitch && sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: dayjs(masterDate),
          };
        }
        if (sessionSwitch && sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: true,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: false,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionDate?.id,
            specialDate: getCheckOutDate({
              isSpecialDay: false,
              checkInCutOffTime: checkInCutOffTimeDate,
              pickupTime: pickupTimeDate,
              workingDays:
                getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
              generalServiceDays:
                getStoreConfigResponse?.body?.daysToDeliver ??
                defaultRegularServiceDays,
              specialServiceDays:
                getStoreConfigResponse?.body?.specialDaysToDeliver ??
                defaultSpecialServiceDays,
            }),
          };
        }

        return sessionDate;
      });
    });
    setSessionTimes((prevSessionTimes) => {
      return prevSessionTimes.map((sessionTime) => {
        const sessionSwitch = sessionSwitches.find(
          (item) => item?.id === sessionTime?.id
        );

        if (sessionSwitch && sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(masterTime),
          };
        }
        if (sessionSwitch && sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }
        if (sessionSwitch && !sessionSwitch.checked && !masterSwitch) {
          return {
            id: sessionTime?.id,
            specialTime: dayjs(
              new Date().setHours(
                getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
                getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
                0,
                0
              )
            ),
          };
        }

        return sessionTime;
      });
    });
  }, [masterSwitch, masterDate, masterTime, cart]);

  const handleOnChangeMasterSwitch = () => {
    setMasterSwitch((prevState) => {
      const newSwitches = JSON.parse(JSON.stringify(sessionSwitches));
      newSwitches.forEach((item: any) => {
        item.checked = !prevState;
      });
      setSessionSwitches(newSwitches);
      const date = new Date();
      date.setHours(
        getStoreConfigResponse?.body ? pickupTimeDate.getHours() : 15,
        getStoreConfigResponse?.body ? pickupTimeDate.getMinutes() : 0,
        0,
        0
      );
      setMasterTime(dayjs(date));
      return !prevState;
    });
    const checkInCutOffTimeString =
      getStoreConfigResponse?.body?.cutOffTime || "10:00:00";
    const pickupTimeString =
      getStoreConfigResponse?.body?.pickupTime || "15:00:00";

    const [
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
    ] = checkInCutOffTimeString.split(":").map(Number);
    const checkInCutOffTimeDate = new Date();
    checkInCutOffTimeDate.setHours(
      checkInCutOffTimeHours,
      checkInCutOffTimeMinutes,
      checkInCutOffTimeSeconds,
      0
    );

    const [pickupTimeHours, pickupTimeMinutes, pickupTimeSeconds] =
      pickupTimeString.split(":").map(Number);
    const pickupTimeDate = new Date();
    pickupTimeDate.setHours(
      pickupTimeHours,
      pickupTimeMinutes,
      pickupTimeSeconds,
      0
    );

    const date = {
      isSpecialDay: !masterSwitch,
      checkInCutOffTime: checkInCutOffTimeDate,
      pickupTime: pickupTimeDate,
      workingDays:
        getStoreConfigResponse?.body?.workingDays ?? defaultWorkingDays,
      generalServiceDays:
        getStoreConfigResponse?.body?.daysToDeliver ??
        defaultRegularServiceDays,
      specialServiceDays:
        getStoreConfigResponse?.body?.specialDaysToDeliver ??
        defaultSpecialServiceDays,
    };
    console.log("Updating Date:", date);
    console.log(
      "checkin cut off time:",
      getStoreConfigResponse?.body?.cutOffTime
    );
    setMasterDate(getCheckOutDate(date));
  };
  const handleOnChangeMasterDate = (date: Dayjs | null) => {
    setMasterDate(date);
  };
  const handleOnChangeMasterTime = (time: Dayjs | null) => {
    setMasterTime(time);
  };

  const handleOnChangeSessionSwitch = (id: string) => {
    const newSwitches = JSON.parse(JSON.stringify(sessionSwitches));
    const index = newSwitches.findIndex((item: any) => item?.id === id);
    newSwitches[index].checked = !newSwitches[index].checked;
    setSessionSwitches(newSwitches);
  };
  const handleOnChangeSessionDate = (id: string, date: Dayjs | null) => {
    const newDates = JSON.parse(JSON.stringify(sessionDates));
    const index = newDates.findIndex((item: any) => item?.id === id);
    if (newDates[index]) {
      newDates[index].specialDate = date;
    }
    setSessionDates(newDates);
  };
  const handleOnChangeSessionTime = (id: string, time: Dayjs | null) => {
    const newTimes = JSON.parse(JSON.stringify(sessionTimes));
    const index = newTimes.findIndex((item: any) => item?.id === id);
    if (newTimes[index]) {
      newTimes[index].specialTime = time;
    }
    setSessionTimes(newTimes);
  };

  const [content, setContent] = useState<PaymentModalContents>(
    PaymentModalContents.SUMMARY
  );

  useEffect(() => {
    initialize();
  }, []);

  const updateHalfOrFullPayment = async (
    paymentRequest: PaymentRequest,
    paymentModalData: IPaymentModalData
  ) => {
    dispatch(newPaymentAction(paymentRequest)).then((res) => {
      const payload = res?.payload as BaseResponseSingle<PaymentCreateResponse>;
      dispatch(
        CartSliceAction.setHalfPayementDetails({
          fullpaymentAmount: billTotal,
          billId: paymentRequest.billId,
          customerId: paymentRequest?.customerId,
          halfPaymentAmount:
            (paymentHalfDetails?.halfPaymentAmount || 0) +
            paymentModalData.paidAmount,
          payments: [payload.body, ...paymentHalfDetails.payments],
        })
      );
    });
  };

  const handleOnCheckout = async (data: IPaymentModalData) => {
    if (tenantId) {
      if (paymentHalfDetails.payments.length === 0) {
        dispatch(
          newBillAction({ tenant: tenantId, paymentType: paymentType })
        ).then(
          async (res) => {
            const payload: any = res?.payload;

            const paymentRequest: PaymentRequest = {
              paymentType: paymentType,
              billId: payload?.id as number,
              customerId: customer?.id as number,
              amount: data.paidAmount,
            };
            updateHalfOrFullPayment(paymentRequest, data);
          }
          // (err) => {
          //   setContent(PaymentModalContents.FAILURE);
          // }
        );
      } else {
        const paymentRequest: PaymentRequest = {
          paymentType: paymentType,
          billId: paymentHalfDetails.billId as number,
          customerId: customer?.id as number,
          amount: data.paidAmount,
        };
        updateHalfOrFullPayment(paymentRequest, data);
      }
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    return () => {
      setContent(PaymentModalContents.SUMMARY);
    };
  }, [open]);

  useEffect(() => {
    if (paidAmount >= billTotal) {
      if (intiaLRender) {
        // this is used to load the actiontile modal only after checkout
        setIntiaLRender(false);
        return;
      }
      setModalActionItems(successActionItems);
      onClose();
      setOpenActionTileModal(true);
      snackbar.showSnackbar("Order Successful", "success");
    }
  }, [paidAmount, paymentHalfDetails]);

  useEffect(() => {
    if (billError) {
      setModalActionItems(failureActionItems);
      onClose();
      setOpenActionTileModal(true);
      snackbar.showSnackbar("Order Failed", "error");
    }
  }, [billError]);

  // const printCopy = (dataSet: IHalfPayment) => {
  //   cart.forEach(async (session) => {
  //     const dateTimeNow = new Date();
  //     const storeCopy = await render(
  //       getStoreBillTemplate({
  //         customer: customer,
  //         translation: translation,
  //         paymentData: dataSet,
  //         session: session,
  //         dropOffDate: dateTimeNow,
  //         dropOffTime: dateTimeNow,
  //         pickUpDate:
  //           sessionDates
  //             ?.find((item) => item?.id === session.sessionId)
  //             ?.specialDate?.toDate() || new Date(),
  //         pickUpTime:
  //           sessionTimes
  //             ?.find((item) => item?.id === session.sessionId)
  //             ?.specialTime?.toDate() || new Date(),
  //         service: session.cartItems[0]?.serviceType?.categoryName,
  //         special:
  //           sessionSwitches.find((item) => item?.id === session.sessionId)
  //             ?.checked || false,
  //       })
  //     );
  //     print(storeCopy);
  //   });
  // };

  const contentNames = {
    summary: "Check in",
    payment: "Payment",
    success: "Payment",
    failure: "Payment",
  };

  const handleOnFinishSummary = () => {
    setContent(PaymentModalContents.PAYMENT);
  };

  const handleOnPayment = (data: IPaymentModalData) => {
    handleOnCheckout(data);
  };

  const handleContinue = () => {
    onClose();
  };

  const handleOnPaymentTypeChange = (paymentType: PaymentTypeEnum) => {
    setPaymentType(paymentType);
  };

  const contentComponents = {
    summary: (
      <CheckInSummary
        onCheckout={handleOnFinishSummary}
        masterSwitch={masterSwitch}
        sessionSwitches={sessionSwitches}
        masterDate={masterDate}
        masterTime={masterTime}
        sessionDates={sessionDates}
        sessionTimes={sessionTimes}
        handleOnChangeMasterSwitch={handleOnChangeMasterSwitch}
        handleOnChangeMasterDate={handleOnChangeMasterDate}
        handleOnChangeMasterTime={handleOnChangeMasterTime}
        handleOnChangeSessionSwitch={handleOnChangeSessionSwitch}
        handleOnChangeSessionDate={handleOnChangeSessionDate}
        handleOnChangeSessionTime={handleOnChangeSessionTime}
      />
    ),
    payment: (
      <Payment
        onCheckout={handleOnPayment}
        onPaymentTypeChange={handleOnPaymentTypeChange}
        paymentType={paymentType}
      />
    ),
    success: <Success onContinue={handleContinue} />,
    failure: <Failure onContinue={handleContinue} />,
  };

  return (
    <>
      <CustomModal
        title={contentNames[content]}
        openModal={open}
        width="95.0714286rem"
        height="64.7857143rem"
        onClose={handleOnClose}
      >
        {contentComponents[content]}
      </CustomModal>

      <ActionTileModal
        openModal={openActionTileModal}
        onClose={() => setOpenActionTileModal(false)}
        actionItems={
          isCheckInSuccessful ? successActionItems : failureActionItems
        }
      />
    </>
  );
};

export default PaymentModal;

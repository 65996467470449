import React, { FC, useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { cloneDeep } from "lodash";

import { CustomButton } from "../../../atoms";
import { Colors } from "../../../../configs";
import { sortArray } from "../../../../util/array-functions";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { updateCartSessionItem } from "../../../../redux/actions/cartAction";
import { IDamage } from "../../../../typings/interfaces/pos/cart-item";
import DamagePromptModal from "../damage-prompt-modal/damage-prompt-modal";
import { getAllDamagesAction } from "../../../../redux/actions/damagesAction";
import { useSnackbarContext } from "../../../../providers/SnackbarProvider";

export interface IDamageSectionProps {}

const DamageSection: FC<IDamageSectionProps> = () => {
  const dispatch = useAppDispatch();
  const { selectedCartItem } = useAppSelector((state) => state.cart);
  const [openPrompt, setOpenPrompt] = useState<boolean>(false);
  const snackbar = useSnackbarContext();

  const { damages, damagesError, damagesLoading } = useAppSelector(
    (state) => state.damage
  );

  const handleOnClosePrompt = () => {
    setOpenPrompt(false);
  };

  useEffect(() => {
    dispatch(getAllDamagesAction());
  }, []);

  useEffect(() => {
    if (damagesError) {
      snackbar.showSnackbar(damagesError, "error");
    }
  }, [damagesError]);

  const allDamages = [
    ...(damages || []),
    {
      id: 100000,
      merchantId: 1,
      storetId: 1,
      damage: "Custom",
      deleted: false,
    },
  ];

  const damagesSorted = sortArray({
    array: allDamages,
    sortByKey: "damage",
    fixedFirstItem: "Custom",
  });

  const handleUpdateCartDamages = (damages: IDamage[]) => {
    const cartItem = cloneDeep(selectedCartItem);
    if (cartItem) {
      cartItem.damages = damages;
      dispatch(updateCartSessionItem({ cartItem }));
    }
  };

  const isAddedDamage = (damage: IDamage) => {
    return selectedCartItem?.damages?.find((item) => item.id === damage.id)?.id
      ? true
      : false;
  };

  const onSelectItem = (damage: IDamage) => {
    if (damage.id === 100000) {
      setOpenPrompt(true);
    } else {
      if (isAddedDamage(damage)) {
        handleUpdateCartDamages(
          selectedCartItem?.damages.filter((item) => damage.id !== item.id) ??
            []
        );
      } else {
        handleUpdateCartDamages([...(selectedCartItem?.damages || []), damage]);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "1.71428571rem",
        background: Colors.BACKGROUND_GRAY,
        borderRadius: "0.71rem",
        padding: "0.928571429rem",
      }}
    >
      <DamagePromptModal
        promptOpen={openPrompt}
        onClose={handleOnClosePrompt}
        onSubmit={handleUpdateCartDamages}
      />
      <Typography
        sx={{ fontSize: "1.71428571rem", color: Colors.TEXT_GRAY_DARK }}
      >
        Damages
      </Typography>

      <Swiper
        style={{ width: "72rem" }}
        spaceBetween={2}
        mousewheel={true}
        direction="horizontal"
      >
        {[...Array(Math.ceil(damagesSorted.length / 36))].map(
          (swipePage, swipePageIndex) => {
            return (
              <SwiperSlide
                key={swipePageIndex}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  flexWrap: "wrap",
                  width: "72rem",
                  gap: "1.07142857rem",
                  rowGap: "0.857142857rem",
                  alignContent: "flex-start",
                  height: "46.7142857rem",
                }}
              >
                {damagesLoading ? (
                  <CircularProgress sx={{ mx: "auto" }} />
                ) : (
                  damagesSorted
                    .slice(swipePageIndex * 36, (swipePageIndex + 1) * 36)
                    .map((damage, i) => (
                      <CustomButton
                        sx={{
                          width: "17.0064286rem",
                          background: isAddedDamage(damage as IDamage)
                            ? Colors.SECONDARY
                            : Colors.MAIN_GRAY,
                          borderRadius: "0.71rem",
                          textTransform: "capitalize",
                          color: Colors.WHITE,
                          fontSize: "1.14rem",
                          lineHeight: "1.33928571rem",
                          padding: "1.07142857rem 0.857142857rem",
                          "&&:hover": {
                            background: Colors.TEXT_GRAY_DARK,
                          },
                          height: "4.28571429rem",
                        }}
                        key={i}
                        onClick={() =>
                          onSelectItem({
                            id: (damage as any).id,
                            label: (damage as any).damage,
                          })
                        }
                      >
                        {(damage as any).damage}
                      </CustomButton>
                    ))
                )}
              </SwiperSlide>
            );
          }
        )}
      </Swiper>
    </Box>
  );
};

export default DamageSection;

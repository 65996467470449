import { createSlice } from "@reduxjs/toolkit";

import { IdamageResponse } from "../../typings/interfaces/responses";
import { getAllDamagesAction } from "../actions/damagesAction";

export interface Damagestate{
    damages: IdamageResponse[] | null;
    damagesLoading: boolean;
    damagesError: string | null;
}

const initialState: Damagestate = {
    damages: null,
    damagesLoading: false,
    damagesError: null,
}

const damagesSlice = createSlice({
    name: "instructions",
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDamagesAction.pending, (state) => {
                state.damages = null;
                state.damagesLoading = true;
                state.damagesError = null;
            })
            .addCase(getAllDamagesAction.fulfilled, (state, action) => {
                state.damagesError = null;
                state.damagesLoading = false;
                state.damages = (action.payload as any).body;
            })
            .addCase(getAllDamagesAction.rejected, (state, action) => {
                state.damages = null;
                state.damagesLoading = false;
                state.damagesError = (action.payload as any).message;
            })         
    }
});

export default damagesSlice.reducer;
export const damagesSliceActions = damagesSlice.actions;
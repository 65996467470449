import React, { FC } from "react";
import { Box, IconButton, Typography } from "@mui/material";

import CustomModal from "../../common/custom-modal/custom-modal";
import { Colors } from "../../../configs";

interface IActionTileModalProps {
  openModal: boolean;
  onClose(): void;
  actionItems: Array<{
    icon: JSX.Element;
    label: string;
    onClick: () => void;
    color: string;
  }>;
  defaultWidth?: string;
  defaultHeight?: string;
}

export const ActionTileModal: FC<IActionTileModalProps> = ({
  openModal,
  onClose,
  actionItems,
  defaultHeight = "11.5rem",
}) => {
  const modalWidth = `${12.89285714285 * actionItems.length}rem`;

  return (
    <CustomModal
      title=""
      openModal={openModal}
      width={modalWidth}
      height={defaultHeight}
      onClose={onClose}
      mode="popup"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          textAlign: "center",
          padding: "2rem",
          marginTop: "-5rem",
          marginLeft: "-3rem",
        }}
      >
        {actionItems.map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <IconButton
                sx={{ marginBottom: "0.5rem", color: item.color }}
                onClick={item.onClick}
                disableRipple
              >
                {React.cloneElement(item.icon, {
                  sx: { fontSize: "3.571rem" },
                })}
              </IconButton>
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: item.color,
                  whiteSpace: "nowrap",
                }}
              >
                {item.label}
              </Typography>
            </Box>

            {/* Vertical line between items */}
            {index < actionItems.length - 1 && (
              <Box
                sx={{
                  width: "2px",
                  height: "8rem",
                  backgroundColor: Colors.BACKSHADE_GRAY,
                  marginX: "3rem",
                  marginTop: "1rem",
                }}
              />
            )}
          </Box>
        ))}
      </Box>
    </CustomModal>
  );
};
